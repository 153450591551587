
import { useThemeContext } from "../../../hooks/usethemeContext";
import { switchTheme } from '../../../assets/styles';
import { PopupButton } from "react-calendly";


const CalendlyButton = () => {
    // define style by darkMode
    const { darkMode } = useThemeContext();
    const { buttonColors } = switchTheme(darkMode);
    const colors = {
        clrTextLight: 'whitesmoke',
        clrButtonBg1: buttonColors.warning,
        clrButtonBg2: buttonColors.warning,
    };

    const styles = {
        display: 'inline-block',
        padding: '.75em 2.7em',
        margin: '.5em auto',
        border: darkMode ? `solid ${colors.clrButtonBg1} 1px` : `solid ${colors.clrButtonBg2} 1px`,
        borderRadius: '.5em',
        color: darkMode ? colors.clrButtonBg1 : "black",
        // fontWeight: '600',
        background: darkMode ? 'transparent' : buttonColors.warning,
        // fontFamily: ('Arial Light'),
        // background: `linear-gradient(to left, ${colors.clrButtonBg1}, ${colors.clrButtonBg2})`
    };

    return <>
        <PopupButton
            className="button-calendly"
            iframeTitle="Calendly Scheduling Page"
            pageSettings={{
                backgroundColor: 'ffffff',
                hideEventTypeDetails: false,
                hideGdprBanner: true,
                hideLandingPageDetails: false,
                primaryColor: '00a2ff',
                textColor: '4d5055'
            }}
            rootElement={document.getElementById("root")}
            styles={styles}
            text={darkMode ? "Book An Appointment!" : <strong>Book An Appointment!</strong>} 
            url={process.env.REACT_APP_CALENDLY}
        />
    </>;
}
 
export default CalendlyButton;
