
import { ThemeProvider } from './hooks/usethemeContext';
import NavBar from './components/layout/Navbar';
import Home from './components/Home';
// import ClientServices from './components/ClientServices';
import BusinessOpportunities from './components/BusinessOpportunities';
import AboutUs from './components/sub-components/AboutUs';
import Contact from './components/Contact';
import Success from './components/Success';
import Redirect from './components/Redirect';
import Footer from './components/layout/Footer';
import Disclaimer from './components/sub-components/Disclaimer';
// import DisclaimerAlert from './components/sub-components/DisclaimerAlert';
import CalendlyPopUp from './components/sub-components/buttons/CalendlyPopUp';
import {
  BrowserRouter as Router,
  Routes,
  Route
} from 'react-router-dom';

function App() {
  return (
    <>
      <Router>
        <ThemeProvider>
          <NavBar />
          <Routes>
            <Route path="/" element={<Home />} />
            {/* <Route path='/clients' element={<ClientServices />} /> */}
            <Route
              path="/business-opportunities"
              element={<BusinessOpportunities />}
            />
            <Route path="/about" element={<AboutUs />} />
            <Route path="/contact" element={<Contact />} />
            <Route path="/success" element={<Success />} />
            <Route path="*" element={<Redirect />} />
          </Routes>
          <Disclaimer />
          <Footer />
          <CalendlyPopUp />
        </ThemeProvider>
      </Router>
    </>
  );
}

export default App;
