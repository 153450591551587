
import { useThemeContext } from "../../hooks/usethemeContext";
import { switchTheme } from '../../assets/styles';
import ThemeSwitch from '../sub-components/buttons/ThemeSwitch';
import { Nav, Navbar, Container, NavDropdown } from 'react-bootstrap';
import logo from '../../assets/images/logo.webp';
// import { socialMedia } from './helper.js';

export default function NavBar() {

  // define button style by darkMode
  const { darkMode } = useThemeContext();
  const { variant, bg } = switchTheme(darkMode);
    
  // const [fb, ig] = socialMedia;

  return <>
    <div className='pb-3 mt-5'>
      <Navbar fixed="top" collapseOnSelect expand="lg" bg={bg} variant={variant}>
        <Container>
          <Navbar.Brand href="/">
            <img
              src={logo}
              width={"45%"}
              className="d-inline-block align-top"
              alt="Company Logo"
            />
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="responsive-navbar-nav" />
          <Navbar.Collapse id="responsive-navbar-nav">
            <Nav className="me-auto">
              <Nav.Link href="/">Welcome</Nav.Link>
              {/* <Nav.Link href="/clients">Client Services</Nav.Link> */}
              <Nav.Link href="/business-opportunities">Business Opportunities</Nav.Link>
              <Nav.Link href="/about">About Us</Nav.Link>
              <Nav.Link href="/contact">Contact Us</Nav.Link>
              <Nav.Link eventKey={3} href="#">
                Taxes ToGo
              </Nav.Link>
            </Nav>
            <Nav className="font-weight-light mx-2">
              {/* <Nav.Link href={fb} target="_blank" rel='noreferrer'>
                <Icon icon="iconoir:facebook-squared" style={ iconStyle }/>
              </Nav.Link>
              <Nav.Link href={ig} target="_blank" rel='noreferrer'>
                <Icon icon="ph:instagram-logo" style={ iconStyle }/>
              </Nav.Link> */}
              {/* <Nav.Link eventKey={2} href="#memes">
                Dank memes
              </Nav.Link> */}
            </Nav>
            <Nav>
              <ThemeSwitch />
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </div>
  </>;
}
