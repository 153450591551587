
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useThemeContext } from "../../hooks/usethemeContext";
import { switchTheme } from '../../assets/styles';
import { getTaxSoftwareText, dealType } from "./helpers/taxSoftware";
import { Container, Col, Row } from "react-bootstrap";
import { Card } from 'react-bootstrap';
import Stripe from "./buttons/Stripe";
import CompTable from "./CompTable";


export default function Pricing() {
    const [data, setData] = useState(null);
    const [promo, setPromo] = useState(false);
    const [promoDate, setPromoDate] = useState('01 Jan 2023')
    const navigate = useNavigate();
    // define styles by darkMode
    const { darkMode } = useThemeContext();
    const { dealStyles, textColor, pricingBackgroundColor } = switchTheme(darkMode);
    const sm = 1, lg = 3;
    const pricing = promo ? "-promo" : "";

    function checkPromo() {
        const now = new Date();
        const promoEndTime = Date.parse(`${promoDate} 23:59:59 EST`);
        if (promo !== true && promoEndTime > now) {
            setPromo(true);
        }
        else if(promo === true && promoEndTime < now) {
            setPromo(false);
            // setData(null)
            setPromoDate('01 Jan 2023')
        }
    }

    useEffect(() => {
        // Check to see if this is a redirect back from Checkout
        const query = new URLSearchParams(window.location.search);
        if (query.get("success")) {
          navigate('/confirmation', { state: { status: 'success' }});
        }
    
        if (query.get("canceled")) {
          navigate('/confirmation', { state: { status: 'canceled' }});
        }
    });
    
    // Fetch data on mount
    useEffect(() => {
        if(data === null) (async function () {
          try {
              const response = await fetch(process.env.REACT_APP_API);
              if (!response.ok) {
              throw new Error(`HTTP error! Status: ${response.status}`);
              }
              const res = await response.json();
              setData((currentState) => { return currentState = res });
              setPromoDate((currentState) => { return currentState = res.promo_date });
          } catch (error) {
              console.error('Caught error:', error);
          }
        }())
    }); // Empty dependency array means this runs once when the component mounts

    useEffect(() => {
        const intervalId = setInterval(checkPromo, 1000 * 1); // checks every second
        return () => clearInterval(intervalId);
    }, [promoDate])
    
    // Stripe Prices
    const firstDealStripePrice = data ? data.first_deal_stripe_price : 'price_1OHUQxHMtfP7ZI3v3eo0CGnZ';
    const secondDealStripePrice = data ? data.second_deal_stripe_price : 'price_1OHUYAHMtfP7ZI3v4GzYQ4bP';
    const thirdDealStripePrice = data ? data.third_deal_stripe_price : 'price_1OHUaoHMtfP7ZI3vYjOYRJHz';
    
    // Promotions/Deals
    const firstDealDiscount = data ? data.first_deal_discount : 600;
    const secondDealDiscount = data ? data.second_deal_discount : 500;
    const thirdDealDiscount = data ? data.third_deal_discount : 0;
    const regularPrice = "Regular Price";
    const taxAffiliateRegularPrice = data ? data.first_deal_regular_price  : 699;
    const taxOfficeRegularPrice = data ? data.second_deal_regular_price  : 599;;
    const mentorshipRegularPrice = data ? data.third_deal_regular_price  : 999;

    const taxAffiliatePromo = taxAffiliateRegularPrice - firstDealDiscount;
    const taxOfficePromo = taxOfficeRegularPrice - secondDealDiscount;
    const mentorshipPromo = mentorshipRegularPrice - thirdDealDiscount;
    
    const taxAffiliate = promo ? taxAffiliatePromo : taxAffiliateRegularPrice;
    const taxOffice = promo ? taxOfficePromo : taxOfficeRegularPrice;
    const mentorship = promo ? mentorshipPromo : mentorshipRegularPrice;
    const earlySpecialHTML = <p className="text-muted">Offer Expires {promoDate}</p>
    const textWarning = 'text-warning'

    const taxSoftwareText = getTaxSoftwareText(
        promo,
        earlySpecialHTML,
        regularPrice,
        taxOfficeRegularPrice,
        taxAffiliateRegularPrice,
        mentorshipRegularPrice
    );

    return <>
        <div className="pt-4 pb-5">
            <Container>
                <div className="text-center" style={textColor}>
                <h2>Free Software Setup</h2>
                <h5>
                    Software & Training in English & Spanish Available
                </h5>
                <h5>
                    <strong>Free - How To Apply For an EFIN & EIN </strong> - I
                    Personally Guide You Through <strong>YOUR </strong> Application
                </h5>
                </div>
                <CompTable />
                <Row
                className={`justify-items-center pricing`} xs={1} sm={sm} lg={lg} > 
                    <Col>
                        <Card
                        className={`bootstrap-card${pricing} shadow ${pricingBackgroundColor} rounded text-center mt-2 mb-5`} style={dealStyles}
                        >
                            <Card.Header as={"h4"}>
                                {dealType.taxAffiliate}
                            </Card.Header>
                            <Card.Body>
                                <Card.Title as={"h1"} className={textWarning}>
                                {taxAffiliate} <small className={textWarning} >/ yr </small>
                                </Card.Title>
                                <Card.Text as={"h5"}>
                                <ul className="list-unstyled mt-3 mb-4">
                                    {taxSoftwareText.taxAffiliate}
                                </ul>
                                </Card.Text>
                                <div className="d-grid position-sticky top-100">
                                <Stripe
                                    price={firstDealStripePrice}
                                    id={'tax-affiliate'}
                                />
                                </div>
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col>
                        <Card
                        className={`bootstrap-card${pricing} shadow ${pricingBackgroundColor} rounded text-center mt-2 mb-5`} style={dealStyles}
                        // className={`bootstrap-card${pricing} shadow ${pricingBackgroundColor} rounded text-center mt-2 mb-5`} style={dealStyles}
                        >
                            <Card.Header as={"h4"}>
                                {dealType.taxOffice}
                            </Card.Header>
                            <Card.Body>
                                <Card.Title as={"h1"} className={textWarning}>
                                {taxOffice} <small className={textWarning} >/ yr </small>
                                </Card.Title>
                                <Card.Text as={"h5"}>
                                <ul className="list-unstyled mt-3 mb-4">
                                    {taxSoftwareText.taxOffice}
                                </ul>
                                </Card.Text>
                                <div className="d-grid position-sticky top-100">
                                <Stripe
                                    price={secondDealStripePrice}
                                    id={'tax-office'}            
                                />
                                </div>
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col>
                        <Card
                        className={`bootstrap-card${pricing} shadow ${pricingBackgroundColor} rounded text-center mt-2 mb-5`} style={dealStyles}
                        >
                            <Card.Header as={"h4"}>
                                {dealType.mentorship}
                            </Card.Header>
                            <Card.Body>
                                <Card.Title as={"h1"} className={textWarning}>
                                {mentorship} <small className={textWarning} >/ yr </small>
                                </Card.Title>
                                <Card.Text as={"h5"}>
                                <ul className="list-unstyled mt-3 mb-4">
                                    {taxSoftwareText.mentorship}
                                </ul>
                                </Card.Text>
                                <div className="d-grid position-sticky top-100">
                                <Stripe 
                                    price={thirdDealStripePrice}
                                    id={'mentorship'}
                                />
                                </div>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </div>
    </>;
}
