
import { useThemeContext } from "../../hooks/usethemeContext";
import { switchTheme } from '../../assets/styles';
import { Col, Container, Row } from "react-bootstrap";
import { NavLink } from "react-router-dom";
import about1 from '../../assets/images/about-1.webp';
// import about2 from '../../assets/images/about-2.webp';


const AboutUs = () => {
	// define style by darkMode
	const { darkMode } = useThemeContext();
	const { mainBannerTextColor, backgroundColor } = switchTheme(darkMode);

	const style = {color: '#0e65d7'};
	const sm = '12', lg = '6'; // setting for 2 people about page
	const styles = { margin:  "auto"};
	const space = 3;

    return (
      <>
        <div className={`about-area mt-5 pb-${space}`}>
			<div className={`text-center pb-${space} border-bottom-0`}>
				<h1 className={mainBannerTextColor}>
					Who We Are!
				</h1>
			</div>
			<Container className="justify-items-center justify">
				<Row className="justify-items-center">
					<Col> {/* setting for 1 person about page */}
					{/* <Col sm={sm} lg={lg}> */} {/* setting for 2 people about page */}
						<Row>
							<div className={`pt-${space}`}>
								<img
									className={`rounded-${space} d-block mb-${space}`}
									style={styles}
									src={about1}
									alt="First slide"
								/>
								<h4 className={`text-center ${mainBannerTextColor}`}>
								Von
								</h4>
								<p>
									Hi I'm Vonnette and I go by Von!  I have 4 beautiful grown children and 5 precious grandchildren from ages 1 - 10 yrs old. I started off as a Janitor and quickly moved up by seeking new opportunities and getting promoted.  I didn't have a college degree, so my reputation spoke on my behalf.  I was working as an Admin Support Specialist (going through a divorce, 4 children, and single parenting) for a large Corporation and The Director approached me one day saying that he was so impressed with my dedication and work ethic, that he had a promotion offer for me.  You can imagine how grateful I was when he made the proposal, and you can also imagine my motivational devastation when he mentioned I needed to have a BS Degree.  I was not going to be able to provide more for my children! This was my crossroad and my pivotal moment in life.  It was because of this missed opportunity that I decided not to miss any other ever again.
								</p>
								<p>
									I started school the very next month in January of 2010. Struggling to study and work at the same time I had to single parent 2 teenagers, 1 toddlers, and another child with a life threating illness.  Life was not easy but it was possible. I received an Associate Degree first and it gave me a promotion in the meantime.  The in 2014 I graduated with the BS in HR and got another promotion. The rest is history as they say.
								</p>
								<p>
									I got into taxes by accident. A friend mentioned that a new Tax Office was opening, and they were looking for help so I applied and got the job. After 1 yr I became the Office Manager where I managed and processed over 400 clients a year. Then after 5 yrs of running an extremely successful Tax business I decided to go on my own and started 360 Tax Strategy.
								</p>
								<p>
									I truly enjoy working with people and helping them realize their financial goals through a simple and tailored Tax Strategy.  I want help you realize your dreams and help you get there.  If you're looking for a business opportunity, I'd love to help you achieve that also.  My goal is to build a team of highly qualified and trained tax professionals dedicated to helping people, their families, and their business reach financial freedom.
								</p>
							</div>
						</Row>
					</Col>
					{/* <Col sm={sm} lg={lg}>
						<Row>
									<div className={`pt-${space}`}>
									<h4 className={`text-center ${mainBannerTextColor}`}>
										Jose
									</h4>
								<img
									className={`rounded-${space} d-block mb-${space}`}
									style={styles}
									src={about2}
									alt="First slide"
								/>
								<p>
									lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud
								</p>
								<p>
									lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud
								</p>
								<p>
									lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud
								</p>
								<p>
									lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud
								</p>
							</div>
						</Row>
					</Col> */}
						<Container className="text-center">
							{/* <h5 className={`text-center ${mainBannerTextColor}`}>
								Our Commitment to You!
							</h5> */}
							{/* <p>
								Our goal is to change people's lives by providing the best services and opportunities to Tax Companies and Tax Preparers. We do business fairly and we don't keep your hard earned money. We provide training and tech-support for all our clients because we believe that you deserve a real opportunity.
							</p> */}
							<h5 className={`text-center ${mainBannerTextColor}`}>
								My Commitment to You!
							</h5>
							<p>
								My goal is to change people's lives by providing the best services and opportunities to Tax Companies and Tax Preparers. We do business fairly and we don't keep your hard earned money. We provide training and tech-support for all our clients because we believe that you deserve a real opportunity.
							</p>
					</Container>
					<NavLink
						to="/contact"
						className={`mt-${space} btn btn-outline-primary`}
					>
						Contact us
					</NavLink>
				</Row>
          	</Container>
        </div>
      </>
    );
};

export default AboutUs;
