
import React, { useState, useEffect } from 'react';


export default function Disclaimer() {
    // Retrieve the dismissal state from local storage
    const initialDismissed = localStorage.getItem('disclaimerAlertDismissed') === 'true';
    
    const [show, setShow] = useState(!initialDismissed);
    const [dismissed, setDismissed] = useState(initialDismissed);

    const styles = {
        alertContainer: {
            position: 'fixed',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            backgroundColor: '#fff',
            color: '#000',
            fontSize: '9px',
            boxShadow: '0 4px 8px rgba(0,0,0,0.1)',
            padding: '16px',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center', /* centers button horizontally */
            alignItems: 'center',    /* centers button vertically */
            width: '65%',
            borderRadius: '4px',
        },
        alertContent: {
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
        },
        closeButton: {
            position: 'relative',
            backgroundColor: '#2196F3',
            color: '#fff',
            border: 'none',
            borderRadius: '4px',
            padding: '8px 16px',
            cursor: 'pointer',
            outline: 'none',
        },
        closeX: {
            alignSelf: 'flex-end',
            cursor: 'pointer',
            marginBottom: '-8px',
            marginRight: '-8px',
        },
    };

    useEffect(() => {
        if (dismissed) {
            setShow(false);
        }
    }, [dismissed]);

    const handleClose = () => {
        localStorage.setItem('disclaimerAlertDismissed', 'true'); // Store in local storage
        setDismissed(true);
        setShow(false);
    };

    return show && !dismissed ? <>

        <div style={styles.alertContainer}>
            <div style={styles.alertContent}>
                <span>By using this website, you consent to our collection and use of personal or business contact information for training and marketing purposes. I agree and acknowledge that 360 Tax Strategy may contact me (via email, text messages, or voice) to my wireless phone number. I also understand that I may opt-out of my consent at any time by unsubscribing.</span>
            </div>
                <button onClick={handleClose} style={styles.closeButton}>Acknowledge</button>
        </div>
    </> : null;
}
